<template>
  <span>
    <b-icon-x-circle-fill :variant="variantDanger" id="tt-task-clear" class="computer-icon ml-1 clickable" @click="clearTasks"/>
    <b-tooltip target="tt-task-clear" triggers="hover">Aktion löschen</b-tooltip>
    <b-icon-arrow-right-circle-fill variant="light-green" id="tt-task-send" class="computer-icon ml-1 clickable"
                                    @click="sendTasks"/>
    <b-tooltip target="tt-task-send" triggers="hover">Aktion abschicken</b-tooltip>
  </span>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "TaskFunctions",
  methods: {
    clearTasks() {
      this.$emit('clear-tasks')
    },
    sendTasks() {
      this.$emit('send-tasks')
    }
  },computed: {
    ...mapGetters(['userColorAccessibility']),
    variantDanger() {
      if(this.userColorAccessibility === 'red-green') { return 'danger-deuteranopia' }
      return 'danger'
    }
  }

}
</script>

<style lang="scss" scoped>
</style>
