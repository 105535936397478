<template>
  <span>
    <b-icon-check-circle-fill variant="primary" id="tt-select-all" class="clickable" @click="selectAll"/>
    <b-tooltip target="tt-select-all" triggers="hover">Alle Computer auswählen</b-tooltip>
    <b-icon-dash-circle-fill variant="primary" id="tt-deselect-all" class="ml-1 clickable" @click="deselectAll"/>
    <b-tooltip target="tt-deselect-all" triggers="hover">Alle Computer abwählen</b-tooltip>
    <b-icon-plus-circle-fill variant="primary" id="tt-task-add" class="ml-3 clickable" @click="onShow"/>
    <b-tooltip target="tt-task-add" triggers="hover">Neue Aktion hinzufügen</b-tooltip>
  </span>
</template>

<script>
export default {
  name: "SelectIcons",
  methods: {
    selectAll() {
      this.$emit('select-all');
    },
    deselectAll() {
      this.$emit('deselect-all');
    },
    onShow() {
      this.$emit('on-show');
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
