<template>
  <span v-if="computer.customer">
    <b-icon-person-fill class="mr-2" :variant="variantSuccess" :id="'tt-customer-' + computer.id"/>
    <b-tooltip :target="'tt-customer-' + computer.id" custom-class="tooltip-width-100" triggers="hover">
      <div class="text-left">
        {{ computer.customer.zuname }}, {{ computer.customer.vorname }} ({{ computer.customer.kdnr }})<br>
        {{ computer.customer.kurs }}<br>
        Klassenraum-{{ computer.customer.intklr }}
      </div>
    </b-tooltip>
  </span>
  <span v-else-if="computer.customer_rpc">
    <b-icon-display-fill class="mr-2" :variant="variantSuccess" :id="'tt-customer-rpc-' + computer.id"/>
    <b-tooltip :target="'tt-customer-rpc-' + computer.id" custom-class="tooltip-width-100" triggers="hover">
      <div class="text-left">
        {{ computer.customer_rpc.zuname }}, {{ computer.customer_rpc.vorname }} ({{ computer.customer_rpc.kdnr }})<br>
        {{ computer.customer_rpc.kurs }}<br>
        Klassenraum-{{ computer.customer_rpc.intklr }}
      </div>
    </b-tooltip>
  </span>
  <span v-else-if="computer.customer_assigned">
    <b-icon-person-check-fill class="mr-2" :variant="variantSuccess" :id="'tt-customer-assigned-' + computer.id"/>
    <b-tooltip :target="'tt-customer-assigned-' + computer.id" custom-class="tooltip-width-100" triggers="hover">
      <div class="text-left">
        {{ computer.customer_assigned.zuname }}, {{ computer.customer_assigned.vorname }} ({{ computer.customer_assigned.kdnr }})<br>
        {{ computer.customer_assigned.kurs }}<br>
        Klassenraum-{{ computer.customer_assigned.intklr }}
      </div>
    </b-tooltip>
  </span>
  <span v-else>
    <b-icon-person-fill class="mr-2" :variant="variantDanger" :id="'tt-no-customer-' + computer.id"/>
    <b-tooltip :target="'tt-no-customer-' + computer.id" custom-class="tooltip-width-100" triggers="hover">
      <div class="text-left">
        Keine Kundeninformationen vorhanden
      </div>
    </b-tooltip>
  </span>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "CustomerInfo",
    props: ['computer'],
  computed: {
    ...mapGetters(['userColorAccessibility']),
    variantSuccess() {
      if(this.userColorAccessibility === 'red-green') {
        return 'success-deuteranopia'
      }
      return 'success'
    },
    variantDanger() {
      if(this.userColorAccessibility === 'red-green') {
        return 'danger-deuteranopia'
      }
      return 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
