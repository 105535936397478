<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="1"/>
        <b-col cols="10">
          <SubHeader class="mb-3">
            <b-row class="mt-2 mb-1">
              <b-col cols="auto" class="mt-1">
                <SelectIcons @select-all="selectAll" @deselect-all="deselectAll" @on-show="onShow"/>
              </b-col>
              <b-col cols="9" class="mt-2 ml-4">
                <b-row :class="{'ml-1': !tasks}">
                  <b-col cols="auto" v-for="task in tasks" :key="task.id" class="pl-0 pr-1">
                    <TaskBar :task="task"/>
                  </b-col>
                  <TaskFunctions v-if="tasks" @clear-tasks="clearTasks" @send-tasks="sendTasks"/>
                </b-row>
              </b-col>
            </b-row>
          </SubHeader>
          <b-list-group v-for="group in groups" :key="group.id">
            <b-list-group-item :class="listGroupItemClasses">
              <b-container fluid>
                <b-row>
                  <b-col cols="2" class="pl-0 pr-0">
                    <b-list-group-item class="group py-2">
                      <b-form-checkbox :id="'group-' + group.id" @input="selectGroup(group, $event)" class="noselect">
                        {{ group.name }}
                      </b-form-checkbox>
                    </b-list-group-item>
                  </b-col>
                  <b-col cols="10">
                    <b-row cols="6">
                      <div v-for="computer in group.computers" :key="computer.id">
                        <ComputerInfo :computer="computer" @select-computer="selectComputer(computer)"/>
                      </div>
                    </b-row>
                  </b-col>
                </b-row>
              </b-container>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col cols="1"/>
      </b-row>
    </b-container>
    <CreateTaskModal :pcCount=selected.length @group-tasks="addTasks" :groupList="true" pcUuid="0"/>
    <ScrollToTop/>
  </div>
</template>

<script>
import {toastWarning} from "@/modules/status";
import {getRequest, putRequest} from "@/modules/requests";
import {mapActions, mapGetters} from "vuex";
import ScrollToTop from "@/components/utility/ScrollToTop";
import TaskBar from "@/components/lists/groupList/tasks/TaskBar";
import SelectIcons from "@/components/lists/groupList/utility/SelectIcons";
import TaskFunctions from "@/components/lists/groupList/tasks/TaskFunctions";
import ComputerInfo from "@/components/lists/groupList/utility/ComputerInfo";
import CreateTaskModal from "@/components/lists/computerList/tasks/createTaskModal/CreateTaskModal";
import SubHeader from "@/components/structure/SubHeader";

export default {
  name: "GroupList",
  components: {
    SubHeader,
    ComputerInfo,
    TaskBar,
    TaskFunctions,
    SelectIcons,
    ScrollToTop,
    CreateTaskModal,
  },
  data() {
    return {
      groups: null,
      selected: [],
      tasks: null
    }
  },
  methods: {
    ...mapActions(['setLoadingState']),
    async loadData() {
      this.setLoadingState(true)
      await getRequest('groupList/' + this.siteSelected.shortname, null, this)
          .then((response) => {
            this.groups = response.data
          })
      this.setLoadingState(false)
    },
    selectGroup(group, event) {
      document.getElementById('group-' + group.id).checked = event
      group.computers.forEach(computer => {
        this.selectComputer(computer, event)
      })
    },
    selectComputer(computer, groupChecked) {
      let index = this.selected.indexOf(computer.id)
      if (groupChecked === true) {
        if (index === -1) {
          this.selected.push(computer.id)
          document.getElementById('computer-' + computer.id).checked = true
        }
      } else if (groupChecked === false) {
        if (index !== -1) {
          this.selected.splice(index, 1)
          document.getElementById('computer-' + computer.id).checked = false
        }
      } else {
        if (index === -1) {
          this.selected.push(computer.id)
          document.getElementById('computer-' + computer.id).checked = true
        } else {
          this.selected.splice(index, 1)
          document.getElementById('computer-' + computer.id).checked = false
        }
      }
    },
    selectAll() {
      this.groups.forEach((group) => {
        this.selectGroup(group, true)
      })
    },
    deselectAll() {
      this.groups.forEach((group) => {
        this.selectGroup(group, false)
      })
    },
    onShow() {
      this.$bvModal.show('createTaskModal-0')
    },
    addTasks(data) {
      this.tasks = data
      this.$bvModal.hide('createTaskModal-0')
    },
    clearTasks() {
      this.tasks = null
    },
    async sendTasks() {
      if(this.selected.length === 0) {
        toastWarning(this, 'Es wurden keine Computer ausgewählt!')
        return
      }
      let data = {
        computers: this.selected,
        tasks: this.tasks
      }
      await putRequest('tasks/group/add', data, this, 'Aktionen erfolgreich hinzugefügt!')
          .then(() => {
            this.tasks = null
            this.deselectAll()
          })
    },
  },
  computed: {
    ...mapGetters(['siteSelected']),
    ...mapGetters(['userThemeLight']),
    listGroupItemClasses() {
      if(this.userThemeLight) {
        return ['mt-2', 'list-row', 'p-0']
      }
      return ['text-white', 'mt-2', 'list-row', 'p-0']
    }
  },
  created() {
    this.loadData()
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/Color';

.group {
  background-color: $alfa-blue;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
</style>
