<template>
  <b-progress :max="1" :id="'tt-task-pending-' + task.command_id" striped class="task mb-1">
    <b-progress-bar :value="1" class="text-white text-border px-1 task-pending">
      <span class="text-left progress-bar-fix" :title="task.displayname">{{ task.displayname }}</span>
      <span class="text-right"><b-icon-stopwatch-fill/></span>
    </b-progress-bar>
    <b-tooltip :target="'tt-task-pending-' + task.command_id" triggers="hover">
      Geplant am {{ task.time_execute_scheduled }}<br>
    </b-tooltip>
  </b-progress>
</template>

<script>
export default {
  name: "TaskBar",
  props: ['task']
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/Tasks';
</style>
